import {
	faLinkedinIn,
	faInstagram,
	faXTwitter,
	faStrava,

} from "@fortawesome/free-brands-svg-icons";
import profileImage from '../assets/home/photoProfile2.jpeg';

const dataProfile = {
	titleProfile: "Hi, I'm ",
	titleProfileHighlight: "Sergio Zafra",
	profileImage: profileImage,
	description: "Business Developer",
	emailAdress: "mailto:s@sergiozafra.com",
	socialMedia: [
		{
			redirectLink: "https://www.linkedin.com/in/sergiozafra/",
			icon: faLinkedinIn,
		},
		{
			redirectLink: "https://twitter.com/serzafra",
			icon: faXTwitter,
		},
		{
			redirectLink: "https://www.instagram.com/serzafra/",
			icon: faInstagram,
		},
		{
			redirectLink: "https://strava.app.link/6rmwrNaz2Lb",
			icon: faStrava,
		},
	],
	dynamicsTitles: [],
	documents: [],
};

export default dataProfile;
