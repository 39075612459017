const dataNavBar = {
	name: "Sergio Zafra",
	links: [
		{
			name: "About me",
			to: "aboutme",
		},
		{
			name: "Gallery",
			to: "gallery",
		},
		{
			name: "Contact me",
			to: "contactme",
		},
	],
    hamburgerCloseIcon: {
		src: "https://img.icons8.com/external-gliphyline-royyan-wijaya/64/000000/external-arrow-arrow-me-glyph-gliphyline-royyan-wijaya-38.png",
		alt: "hamburger menu close",
	},
	hamburgerOpenIcon: {
		// src: "https://img.icons8.com/stickers/50/000000/menu.png",
		src: "https://img.icons8.com/external-kiranshastry-lineal-kiranshastry/64/000000/external-grid-alignment-and-tools-kiranshastry-lineal-kiranshastry-1.png",
		alt: "hamburger menu open",
	},
};

export default dataNavBar;
