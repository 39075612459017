import React from "react";
import { dataAboutMe, dataProfile } from "../../../dataPortfolio";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import envelopRegular from "../../../assets/home/envelope-regular.svg";
const Landing = () => (
  <div className="flex flex-col items-center justify-center min-h-dvh bg-background text-foreground">
    <header className="w-full max-w-md px-4 py-6">
      <div className="flex flex-col items-center gap-2">
        <div className="text-3xl font-bold">
          {dataProfile.titleProfileHighlight}
        </div>
        <div className="text-muted-foreground">{dataProfile.description}</div>
      </div>
    </header>
    <main className="w-full max-w-md px-4 py-2">
      <div className="flex flex-col items-center gap-4">
        <div className="rounded-full w-32 h-32 bg-muted overflow-hidden">
          <img
            src={dataProfile.profileImage}
            alt="Profile"
            width={128}
            height={128}
            className="w-full h-full object-cover"
            style={{ aspectRatio: "128/128", objectFit: "cover" }}
          />
        </div>
        <div className="text-center text-muted-foreground">
          {dataAboutMe.knowMeDescriptions.map((description) => (
            <p className="mt-2">
              <div dangerouslySetInnerHTML={{ __html: description }}></div>
            </p>
          ))}
        </div>
        <div className="flex gap-4">
          {dataProfile.socialMedia.map((sMedia) => (
            <a href={sMedia.redirectLink} target="_blanck">
              <FontAwesomeIcon icon={sMedia.icon} size="2x" />
            </a>
          ))}
          <a href={dataProfile.emailAdress} style={{ cursor: "pointer" }}>
            <img src={envelopRegular} alt="Email" width={32} height={32} />
          </a>
        </div>
      </div>
    </main>
  </div>
);

export default Landing;
