import React, {useState} from 'react';
import './index.css';
// import Profile from './profile/profile';
// import NavBar from './navBar/navBar';
// import AboutMe from './aboutMe/aboutMe';
// import Gallery from './gallery/gallery';
// import ContactMe from './contactMe/contactMe';
// import Footer from './footer/footer';
// import ProfileFooter from './profileFooter/profileFooter';
import Landing from './landing/landing.tsx';


const Home = () => {

  const [isOpen, setisOpen] = useState(false);
  const toggle = () => {
    setisOpen(!isOpen);
  };

  return (
    <div className='home-container'>
        <Landing/>
      {/* //     <NavBar toggle={toggle}/>
      //     <Profile/>
      //     <ProfileFooter />
      //     <AboutMe/>
      //     <Gallery/>
      //     <ContactMe/>
      //     <Footer/> */}
      </div>
  );
};

export default Home;
