const dataAboutMe = {
  title: "About me",
  knowMetitle: "",
  knowMeDescriptions: [
    "Hi, I’m Sergio, a business professional passionate about startups, technology, corporate finance, and economy. I work at AWS Startups, based in San Francisco, teaming up with VCs and their portfolio companies to help them grow. ",
    "When I’m not working, I’m running marathons—50 so far, including the six World Marathon Majors. I’m always up for connecting with like-minded professionals, so feel free to reach out!",
  ],
  buttons: [
    {
      name: "Contact me",
      redirectLink: "contactme",
    },
  ],
};

const dataTechSkills = {
  iconSkillsTitle: "",
  skills: [],
};

export { dataAboutMe, dataTechSkills };
